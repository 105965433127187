@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.dynamic-h {
		height: calc(var(--dh));
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.toggle-checkbox:checked {
		@apply: right-0 border-green-400;
		right: 0;
		border-color: #000000;
	}
	.toggle-checkbox:checked + .toggle-label {
		@apply: bg-green-400;
		background-color: #000000;
	}
}
